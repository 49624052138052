<template>
  <div
    v-if="product && hasProperty"
    class="property-badge"
    :class="[
      `property-badge--${color}`,
      {'property-badge--absolute': absolute},
      {'property-badge--large': large},
    ]"
  >
    <div
      class="property-badge__inner"
      :class="[
        `property-badge__inner--${color}`,
        {'property-badge__inner--absolute': absolute},
        {'property-badge__inner--large': large},
      ]"
    >
      <div class="property-badge__icon" :class="[`icon-${icon}`]"></div>
      <div class="property-badge__name" v-if="name">{{ name }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  property: {
    type: String,
    default: 'Vegan',
  },
  product: {
    type: Object,
    default: () => ({}),
  },
  absolute: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: 'vegan',
  },
  color: {
    type: String,
    default: 'green',
  },
  name: {
    type: String,
    default: '',
  },
});

const hasProperty = computed(() => {
  if (
    props.product?.properties?.find(
      (property: {name: string}) => property.name === props.property
    )
  ) {
    return true;
  } else {
    return false;
  }
});
</script>

<style lang="scss">
.property-badge {
  // margin-top: $spacing-xs;
  // margin-bottom: $spacing-xs;
  display: inline-block;
  white-space: nowrap;

  &--absolute {
    position: absolute;
    left: $spacing-base;
    top: $spacing-base;
  }
}

.property-badge__inner {
  display: inline-flex;
  align-items: center;
  gap: $spacing-xxxs;
  border-radius: 30px;
  width: auto;
  padding: $spacing-xxxs $spacing-sm;

  &--large {
    padding: $spacing-xxs $spacing-base;
    gap: $spacing-xxs;
  }

  &--green {
    color: $color-white;
    background: $color-green;
  }

  &--black {
    color: $color-white;
    background: $color-black;
  }

  &--red {
    color: $color-white;
    background: $color-red;
  }

  &--blue {
    color: $color-white;
    background: $color-blue;
  }

  &--yellow {
    color: $color-black;
    background: $color-yellow;
  }

  &--gray {
    color: $color-black;
    background: $color-light-gray;
  }
}

.property-badge__icon {
  font-size: $text-base;
  // font-weight: $font-bold;

  .property-badge--large & {
    font-size: $text-xl;
  }
}

.property-badge__name {
  font-size: $text-sm;
  color: $color-white;

  .property-badge__inner--large & {
    font-size: $text-md;
  }
}
</style>
