export default defineNuxtPlugin((nuxtApp) => {
  const store = useDefaultStore();
  const router = useRouter();
  const states = useStates();

  //Listen to consent manager

  let cmpStatusTry = 0;

  const addCmpEventlistener = () => {
    __cmp(
      'addEventListener',
      [
        'consent',
        () => {
          store.setCookieConsent(true);
        },
        false,
      ],
      null
    );
  };

  const checkCmpStatus = () => {
    if (
      window &&
      window.cmpCurrentStatus &&
      window.cmpCurrentStatus === 'unknown'
    ) {
      addCmpEventlistener();
    } else if (
      window &&
      window.cmpCurrentStatus &&
      window.cmpCurrentStatus === 'loading'
    ) {
      addCmpEventlistener();
    } else if (
      window &&
      window.cmpCurrentStatus &&
      window.cmpCurrentStatus === 'legitimateInterest'
    ) {
      addCmpEventlistener();
    } else if (
      window &&
      window.cmpCurrentStatus &&
      window.cmpCurrentStatus === 'consent'
    ) {
      store.setCookieConsent(true);
    } else {
      setTimeout(() => {
        cmpStatusTry++;
        if (cmpStatusTry > 10) {
          return;
        }
        checkCmpStatus();
      }, 2000);
    }
  };

  checkCmpStatus();

  //Fetch global Strapi Options

  const strapiGlobalOptions = {
    method: 'GET',
    progress: false,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  $fetch(
    `${nuxtApp.$config.public.strapi.url}/api/global-option?populate=deep,10`,
    strapiGlobalOptions
  )
    .then((response) => {
      if (response?.data?.attributes?.Active) {
        store.setStrapiGlobalOptions(response.data.attributes);
      }
    })
    .catch((error) => {});

  //get Strapi Global Promotions
  const strapiPromotionOptions = {
    method: 'get',
    progress: false,
  };

  $fetch(
    `${nuxtApp.$config.public.strapi.url}/api/global-promotion?populate=deep,10`,
    strapiPromotionOptions
  )
    .then((response) => {
      store.setStrapiGlobalOptions(response.data.attributes);
      if (
        response &&
        response.data &&
        response.data.attributes &&
        response.data.attributes.Active
      ) {
        store.setGlobalPromotion(response.data.attributes);
      }
    })
    .catch((error) => {});

  router.beforeEach((to) => {});

  router.afterEach(() => {
    states.setIsSwitchingPages(false);
    states.setIsSwitchingVariant(false);
    states.setSkeletonType(null);
  });
});
