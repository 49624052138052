import {PAGE_SEARCH} from './pages';

export const getSortingLabel = (sorting) => {
  if (!sorting || !sorting.field) {
    return '';
  }

  const ascLabel = 'ascending';
  const descLabel = 'descending';

  const label =
    sorting.order && (sorting.order === 'desc' ? descLabel : ascLabel);
  return label ? `${sorting.field} ${label}` : sorting.field;
};

/**
 * converts non-alphanumerical characters into dashes
 * used for parsing slot codes from names
 * @param {string} phrase
 */
export const simplifyString = (phrase) =>
  (typeof phrase === 'string' && phrase.replace(/[^a-zA-Z0-9]+/g, '-')) ||
  phrase;

export const formatDate = (date, format = `DD.MM.YYYY`) => {
  // dayjs(date).format(format);
  const dateFormat = new Date(date);
  const day = dateFormat.getMonth() + 1;
  const month = dateFormat.getMonth() + 1;
  return (
    (day < 10 ? '0' + day : day) +
    '.' +
    (month < 10 ? '0' + month : month) +
    '.' +
    dateFormat.getFullYear()
  );
};

export const getSearchPageUrl = (searchTerm) =>
  `${PAGE_SEARCH}?query=${encodeURIComponent(searchTerm)}`;

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({key: v, values: [x]});
    }
    return rv;
  }, []);
};

const findParent = (id, flatCategoryList) => {
  if (flatCategoryList && flatCategoryList.length) {
    return flatCategoryList.find((item) => item.id === id);
  }
  return null;
};

export const checkForSpecies = (category, flatCategoryList) => {
  if (
    //Check if current category has animal species
    category &&
    category.extensions &&
    category.extensions.animalSpecies &&
    category.extensions.animalSpecies.length
  ) {
    return category.extensions.animalSpecies[0];
  } else if (category?.parentId) {
    //Check if parent has animal species
    const parentCategory = findParent(category?.parentId);

    if (parentCategory) {
      checkForSpecies(parentCategory, flatCategoryList);
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const clearAndUpper = (text) => {
  return text.replace(/-/, '').toUpperCase();
};

export const toPascalCase = (text) => {
  return text.replace(/(^\w|-\w)/g, clearAndUpper);
};

export const toCamelCase = (text) => {
  return text.replace(/-\w/g, clearAndUpper);
};

export const kebabToCamel = (str) =>
  str.replace(/-./g, (m) => m.toUpperCase()[1]);

export const camelToKebab = (str) =>
  str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();

export const findNestedObject = (entireObj, keyToFind, valToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export const removeHtmlAttributes = (
  string,
  attribute = 'style',
  output = 'innerHTML'
) => {
  let result = new DOMParser().parseFromString(string, 'text/html');

  const attributeRemoval = (items) => {
    items.forEach((item) => {
      if (item.attributes && item.attributes.length) {
        item.removeAttribute(attribute);

        // for (let i = 0; i < item.attributes.length; i++) {
        //   item.removeAttribute(item.attributes[i].name)
        // }

        if (item.childNodes && item.childNodes.length) {
          attributeRemoval(item.childNodes);
        }
      }
      return item;
    });
  };

  attributeRemoval(result.body.childNodes);

  // console.dir(result.body.childNodes)

  return result.body[output];
};

export const cleanText = (string) => {
  return new DOMParser().parseFromString(string, 'text/html').body.innerText;
};

const getLastItemFrompath = (str) => {
  const arr = str.split('/');

  return arr[arr.length - 1];
};

// Get slug from a string path or a shopware category object
export const getSlug = (item) => {
  if (typeof item === 'string') {
    return getLastItemFrompath(item);
  } else {
    const path = item?.seoUrls?.[0]?.seoPathInfo;
    return getLastItemFrompath(path) ?? '';
  }
};

const groupGlossar = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const groupArray = (arr, indicatorString) => {
  return arr.map((item) => {
    return {
      ...item,
      group: item[indicatorString]?.[0]?.toUpperCase() ?? '',
    };
  });
};

const fieldsByLetter = (group) => {
  const glossar = [
    ...new Set(
      group.map((item) => {
        return item.group;
      })
    ),
  ];

  return Array.from(groupGlossar(group, (item) => item.group));
};

export const glossarize = (arr, indicatorString) => {
  const groupedArray = groupArray(arr, indicatorString);
  groupedArray.sort(function (a, b) {
    return a[indicatorString].localeCompare(b[indicatorString]);
  });
  return fieldsByLetter(groupedArray);
};

export const lightenColor = (color, percent) => {
  var num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

export const hexToUUID = (hex) => {
  // Sicherstellen, dass die HEX-Zeichenkette 32 Zeichen lang ist
  if (hex.length !== 32) {
    throw new Error('Die HEX-Zeichenkette muss genau 32 Zeichen lang sein.');
  }

  // UUID-Format anwenden: xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
  return (
    hex.slice(0, 8) +
    '-' +
    hex.slice(8, 12) +
    '-' +
    hex.slice(12, 16) +
    '-' +
    hex.slice(16, 20) +
    '-' +
    hex.slice(20, 32)
  );
};

export const getBrandName = (product, dash = false) => {
  if (product?.extensions?.productBrand?.at(0)?.brand?.name) {
    return (
      product.extensions.productBrand[0]?.brand?.name + (dash ? ' - ' : '')
    );
  } else {
    return '';
  }
};

export const getCategoryAnimalSpecies = (category, dash = false) => {
  if (category?.extensions?.animalSpecies?.length) {
    return (
      category?.extensions?.animalSpecies?.at(0)?.name + (dash ? ' - ' : '')
    );
  } else {
    return '';
  }
};
