import {defineStore} from 'pinia';

export const useStates = defineStore('states', {
  state: () => {
    return {
      cartOpen: false,
      navOpen: false,
      modalOpen: false,
      loginModalOpen: false,
      isLoading: false,
      isSwitchingPages: false,
      isSwitchingVariant: false,
      skeletonType: null,
      globalPopupOpen: false,
    };
  },

  // contains your mutations
  actions: {
    setCartOpen(value) {
      this.cartOpen = value;
    },
    setNavOpen(value) {
      this.navOpen = value;
    },
    setModalOpen(value) {
      this.modalOpen = value;
    },
    setLoginModalOpen(value) {
      this.loginModalOpen = value;
    },
    setIsLoading(value) {
      this.isLoading = value;
    },
    setIsSwitchingPages(value) {
      this.isSwitchingPages = value;
    },
    setIsSwitchingVariant(value) {
      this.isSwitchingVariant = value;
    },
    setSkeletonType(value) {
      this.skeletonType = value;
    },
    setGlobalPopupOpen(value) {
      this.globalPopupOpen = value;
    },
  },
  // your root getters
  getters: {
    getCartOpen() {
      return this.cartOpen;
    },
    getNavOpen() {
      return this.navOpen;
    },
    getModalOpen() {
      return this.modalOpen;
    },
    getLoginNavOpen() {
      return this.loginModalOpen;
    },
    getIsLoading() {
      return this.isLoading;
    },
    getIsSwitchingPages() {
      return this.isSwitchingPages;
    },
    getIsSwitchingVariant() {
      return this.isSwitchingVariant;
    },
    getSkeletonType() {
      return this.skeletonType;
    },
    getGlobalPopupOpen() {
      return this.globalPopupOpen;
    },
  },
});
