export function checkHydration() {
  const temp = useTempStore();

  const isHydrating = computed(() => {
    return temp.getIsHydrating;
  });
  const finishHydrating = computed(() => {
    return !temp.getIsHydrating;
  });

  return {
    isHydrating,
    finishHydrating,
  };
}

export function useUtils() {
  const {sendNotification} = useNotification();

  const copyCode = (code) => {
    navigator.clipboard.writeText(code.toLowerCase());
    if (document != undefined) {
      document.cookie = `coupon=${code.toLowerCase()}`;
    }

    sendNotification({
      type: 'success',
      message: `Code ${code} kopiert`,
      icon: 'copy',
    });
  };

  return {
    copyCode,
  };
}
