export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('body-scroll-lock', {
    mounted(el, binding) {
      if (!binding.hasOwnProperty('value') || binding.value !== false) {
        setTimeout(() => {
          el.classList.add('sub-scroll');
          if (document.querySelectorAll('.sub-scroll').length === 1) {
            document.querySelector('body')?.classList.add('deactivate-scroll');
          }
        }, 0);
      }
    },
    updated(el) {},
    unmounted(el) {
      el.classList.remove('sub-scroll');
      if (document.querySelectorAll('.sub-scroll').length === 0) {
        document.querySelector('body')?.classList.remove('deactivate-scroll');
      }
    },
  });
});
