<template>
  <div class="header__meta-item">
    <div @click="openModal()">
      <div class="header-meta__icon icon-christmas-tree"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const states = useStates();

const modalOpen = ref(false);

const openModal = () => {
  states.setGlobalPopupOpen(true);
};
</script>

<style scoped></style>
