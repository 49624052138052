export function getPageType(path) {
  const store = useDefaultStore();
  const isPreview = path.path.startsWith(`/api/preview`) ? true : false;
  const app = useNuxtApp();

  let pageType = 'fallback';
  if (path.path == '/') {
    pageType = 'homepage';
  } else if (
    path.path.startsWith('/' + app.$i18n.t('path.magazine')) ||
    (isPreview && path.query.type === 'magazines')
  ) {
    pageType = 'magazine';
  } else if (
    path.path.startsWith('/' + app.$i18n.t('path.brands') + '/') ||
    path.path.startsWith('/' + app.$i18n.t('path.manufacturer') + '/')
  ) {
    const pathParams = path.path.slice(1).split('/');
    if (pathParams.length < 3) {
      pageType = 'brand';
    } else {
      pageType = 'subbrand';
    }
  } else if (
    path.path.startsWith('/' + app.$i18n.t('path.landingpage')) ||
    (isPreview && path.query.type === 'landingpages')
  ) {
    pageType = 'landingpage';
  } else if (path.path.startsWith('/' + app.$i18n.t('path.ads'))) {
    pageType = 'ads';
  } else if (
    (path.path.startsWith('/p/') || path.path.startsWith('/detail/')) &&
    store.getCurrentProduct &&
    store.getCurrentProduct.id
  ) {
    pageType = 'product';
  } else if (
    path.path.startsWith('/login') ||
    path.path.startsWith('/account')
  ) {
    pageType = 'account';
  } else if (
    store.getCurrentListing &&
    Object.keys(store.getCurrentListing).length
  ) {
    pageType = 'navigation';
  } else {
    pageType = 'fallback';
  }

  return pageType;
}
