import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_934tR0MIeY0ZMeta } from "/app/pages/account/addresses/add/[...slug].vue?macro=true";
import { default as index9U5bSu0zfPMeta } from "/app/pages/account/addresses/add/index.vue?macro=true";
import { default as indexbO7fuQirP2Meta } from "/app/pages/account/addresses/index.vue?macro=true";
import { default as _91_46_46_46slug_93VfPdeenDFYMeta } from "/app/pages/account/orders/[...slug].vue?macro=true";
import { default as indexv0iIYE4xFlMeta } from "/app/pages/account/orders/index.vue?macro=true";
import { default as indexKCiVKYb9p3Meta } from "/app/pages/account/profile/index.vue?macro=true";
import { default as passwordv4QIVRuQi3Meta } from "/app/pages/account/recover/password.vue?macro=true";
import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as _91_46_46_46slug_933g1UsojjwLMeta } from "/app/pages/ads/[...slug].vue?macro=true";
import { default as campaignsCues1l4U7fMeta } from "/app/pages/campaigns.vue?macro=true";
import { default as checkoutLpmDViNIqPMeta } from "/app/pages/checkout.vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as herstellerVtadWA6y9iMeta } from "/app/pages/hersteller.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as order_45successQyCFEz3FghMeta } from "/app/pages/order-success.vue?macro=true";
import { default as payment_45failureFFKCHq00twMeta } from "/app/pages/payment-failure.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as calories_45calculationPaIuLMqawnMeta } from "/app/pages/static/calories-calculation.vue?macro=true";
import { default as _91entry_93LRiJGpRYXHMeta } from "/app/pages/wiki/food/[animal]/[entry].vue?macro=true";
import { default as indexz53h2KHjIrMeta } from "/app/pages/wiki/food/[animal]/index.vue?macro=true";
import { default as indexxCneFS2sdpMeta } from "/app/pages/wiki/food/index.vue?macro=true";
import { default as wishlisttHOV2I8jKWMeta } from "/app/pages/wishlist.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/app/pages/account.vue"),
    children: [
  {
    name: "account-addresses-add-slug",
    path: "addresses/add/:slug(.*)*",
    component: () => import("/app/pages/account/addresses/add/[...slug].vue")
  },
  {
    name: "account-addresses-add",
    path: "addresses/add",
    component: () => import("/app/pages/account/addresses/add/index.vue")
  },
  {
    name: "account-addresses",
    path: "addresses",
    component: () => import("/app/pages/account/addresses/index.vue")
  },
  {
    name: "account-orders-slug",
    path: "orders/:slug(.*)*",
    component: () => import("/app/pages/account/orders/[...slug].vue")
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/app/pages/account/orders/index.vue")
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("/app/pages/account/profile/index.vue")
  },
  {
    name: "account-recover-password",
    path: "recover/password",
    meta: passwordv4QIVRuQi3Meta || {},
    component: () => import("/app/pages/account/recover/password.vue")
  }
]
  },
  {
    name: "ads-slug",
    path: "/ads/:slug(.*)*",
    meta: _91_46_46_46slug_933g1UsojjwLMeta || {},
    component: () => import("/app/pages/ads/[...slug].vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/app/pages/campaigns.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: checkoutLpmDViNIqPMeta || {},
    component: () => import("/app/pages/checkout.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/app/pages/forgot-password.vue")
  },
  {
    name: "hersteller",
    path: "/hersteller",
    component: () => import("/app/pages/hersteller.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "order-success",
    path: "/order-success",
    component: () => import("/app/pages/order-success.vue")
  },
  {
    name: "payment-failure",
    path: "/payment-failure",
    component: () => import("/app/pages/payment-failure.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/app/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "static-calories-calculation",
    path: "/static/calories-calculation",
    component: () => import("/app/pages/static/calories-calculation.vue")
  },
  {
    name: "wiki-food-animal-entry",
    path: "/wiki/food/:animal()/:entry()",
    component: () => import("/app/pages/wiki/food/[animal]/[entry].vue")
  },
  {
    name: "wiki-food-animal",
    path: "/wiki/food/:animal()",
    component: () => import("/app/pages/wiki/food/[animal]/index.vue")
  },
  {
    name: "wiki-food",
    path: "/wiki/food",
    component: () => import("/app/pages/wiki/food/index.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/app/pages/wishlist.vue")
  }
]