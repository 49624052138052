<template>
  <div
    class="image"
    :class="['image--' + ratio, 'image--' + fit, {'image--rounded': rounded}]"
  >
    <picture>
      <img
        :src="src ? src : '/placeholder-1.jpg'"
        :srcset="srcsetString"
        :width="width"
        :height="height"
        :alt="alt"
        :title="title"
        :loading="lazy ? 'lazy' : 'eager'"
      />
    </picture>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  rounded: {
    type: Boolean,
    default: false,
  },
  lazy: {
    type: Boolean,
    default: true,
  },

  ratio: {
    type: String,
    default: 'default',
  },
  fit: {
    type: String,
    default: 'contain',
  },
  /**
   * Main source url for the image
   */
  src: {
    type: String,
    required: false,
  },
  /**
   * Array of images' source, dimension and breakpoints to generate srcset attribute
   */
  srcset: {
    type: Array,
    default: () => [],
  },
  /**
   * Alternative text in case image is not loaded. Use empty string " " for decorative-only image and full text otherwise
   */
  alt: {
    type: String,
    // required: true,
    default: '',
  },
  title: {
    type: String,
    // required: true,
    default: '',
  },
  /**
   * Width of the image
   */
  width: {
    type: [String, Number],
    default: '',
  },
  /**
   * Height of the image
   */
  height: {
    type: [String, Number],
    default: '',
  },
  /**
   * Url source of the image's placeholder while it is loading.
   */
});

const srcsetString = ref([]);

onMounted(() => {
  if (props.srcset) {
    props.srcset.forEach((item) => {
      const itemValue = item.url + ' ' + item.width + 'w';
      srcsetString.value.push(itemValue);
    });
  }

  srcsetString.value = srcsetString.value.join();
});
</script>

<style lang="scss">
.image {
  picture {
    width: 100%;
    height: 100%;
    display: block;
  }

  &--default {
    width: 100%;
    height: 100%;
  }

  &--2-3 {
    aspect-ratio: 2 / 3;
  }

  &--3-2 {
    aspect-ratio: 3 / 2;
  }

  &--1-1 {
    aspect-ratio: 1 / 1;
  }

  &--16-9 {
    aspect-ratio: 16 / 9;
  }

  &--2-1 {
    aspect-ratio: 2 / 1;
  }

  &--3-1 {
    aspect-ratio: 3 / 1;
  }

  &--3-4 {
    aspect-ratio: 3 / 4;
  }

  &--4-3 {
    aspect-ratio: 4 / 3;
  }

  &--cover img {
    object-fit: cover;
  }

  &--contain {
    img {
      object-fit: contain;
      // width: auto !important;
      // height: auto !important;
      // max-width: 100%;
      // max-height: 100%;
      // margin: auto;
    }
  }

  &--rounded {
    border-radius: $radius-xs;

    picture,
    img {
      border-radius: $radius-xs;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-position: center;
  }
}
</style>
