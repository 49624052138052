import defaults from '~/public/pwa-defaults/api-defaults.json';

interface LoginParams {
  username: string;
  password: string;
  firstLogin: boolean;
}

export function useShopware() {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const tempStore = useTempStore();
  const shopwareStore = useShopwareStore();
  const token = useCookie('sw-context-token', {
    expires: new Date(Date.now() + 31536000000),
  });
  const {getCart, emptyCart} = useCart();
  const {requestHeaders, requestPath} = utilRequests();
  const {fetchWishlist} = useWishlist();
  const {swRequest} = useRequest();
  const {sendNotification} = useNotification();
  const {user, isLoggedIn, isGuestSession} = useUser();

  const setSsoToken = async (response) => {
    if (
      response.headers.get(app.$config.public.sso.ResponseHeaders.ACCESS_TOKEN)
    ) {
      localStorage.setItem(
        app.$config.public.sso.ResponseHeaders.ACCESS_TOKEN,
        response.headers.get(
          app.$config.public.sso.ResponseHeaders.ACCESS_TOKEN
        )
      );
      localStorage.setItem(
        app.$config.public.sso.ResponseHeaders.ACCESS_TOKEN_EXPIRES_IN,
        response.headers.get(
          app.$config.public.sso.ResponseHeaders.ACCESS_TOKEN_EXPIRES_IN
        )
      );
      localStorage.setItem(
        app.$config.public.sso.ResponseHeaders.REFRESH_TOKEN,
        response.headers.get(
          app.$config.public.sso.ResponseHeaders.REFRESH_TOKEN
        )
      );
      localStorage.setItem(
        app.$config.public.sso.ResponseHeaders.REFRESH_TOKEN_EXPIRES_IN,
        response.headers.get(
          app.$config.public.sso.ResponseHeaders.REFRESH_TOKEN_EXPIRES_IN
        )
      );
    }
  };

  const sessionContext = computed(() => {
    return shopwareStore.getContext;
  });

  //Get Session Context
  const refreshSessionContext = async () => {
    await $fetch(`${requestPath}/context`, {
      method: 'GET',
      ...requestHeaders.value,
    }).then(async (response: any) => {
      shopwareStore.setContext(response);
      shopwareStore.setContextToken(response.token);
      shopwareStore.setUser(response?.customer);
      token.value = response.token;
      getCart();
    });
  };

  //Login
  const login = async ({
    username,
    password,
    firstLogin = false,
  }: LoginParams) => {
    return await $fetch
      .raw(`${requestPath}/account/login`, {
        method: 'POST',
        ...requestHeaders.value,
        body: {
          username: username,
          password: password,
        },
      })
      .then(async (response: any) => {
        shopwareStore.setContextToken(response._data.contextToken);
        sendNotification({
          type: 'success',
          message: 'Erfolgreich angemeldet',
        });
        setSsoToken(response);
        loadAddresses();
        await refreshSessionContext();
        const currentCart = shopwareStore.getCart?.lineItems;
        getCart().then(async (response) => {
          store.setCartComparison({
            currentCart: currentCart,
            fetchedCart: response.lineItems,
          });
        });
        fetchWishlist(firstLogin);
        return response._data;
      })
      .catch((err) => {
        sendNotification({
          type: 'error',
          message: 'Es gab leider ein Problem',
        });
        throw err;
      });
  };

  const logout = async () => {
    if (isLoggedIn.value && !isGuestSession.value) {
      await $fetch(`${requestPath}/account/logout`, {
        method: 'POST',
        ...requestHeaders.value,
      }).then(async (response: any) => {
        sendNotification({
          type: 'success',
          message: 'Erfolgreich abgemeldet',
        });
        shopwareStore.setContextToken(null);
        shopwareStore.setWishlist(null);
        token.value = null;
        shopwareStore.setUser(null);
        emptyCart();
        tempStore.setLocalWishlist([]);
        refreshSessionContext().then(navigateTo('/'));
      });
    } else {
      sendNotification({
        type: 'success',
        message: 'Erfolgreich abgemeldet',
      });
      shopwareStore.setContextToken(null);
      token.value = null;
      shopwareStore.setUser(null);
      emptyCart();
      tempStore.setLocalWishlist([]);
      refreshSessionContext().then(navigateTo('/'));
    }
  };

  const register = async (data: any) => {
    return $fetch
      .raw(`${requestPath}/account/register`, {
        method: 'POST',
        ...requestHeaders.value,
        body: data,
      })
      .then(async (res) => {
        shopwareStore.setContextToken(res.headers.get('sw-context-token'));
        if (res._data.guest) {
          shopwareStore.setUser(res._data);
          refreshSessionContext();
          sendNotification({
            type: 'success',
            message: 'Du kannst jetzt einkaufen',
          });
        } else {
          await login({
            username: data.email,
            password: data.password,
            firstLogin: true,
          });
        }
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const sendResetPasswordMail = async (email: string) => {
    return swRequest('/account/recovery-password', {
      email: email,
      storefrontUrl: app.$config.public.frontendUrl,
    })
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const resetPassword = async (
    password: string,
    passwordConfirm: string,
    hash: string
  ) => {
    return swRequest('/account/recovery-password-confirm', {
      hash: hash,
      newPassword: password,
      newPasswordConfirm: passwordConfirm,
    })
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const loadAddresses = async () => {
    return swRequest('/account/list-address', {
      associations: {salutation: {}},
      includes: {
        customer_address: [
          'firstName',
          'lastName',
          'city',
          'zipcode',
          'country',
          'countryId',
          'id',
          'phoneNumber',
          'state',
          'salutation',
          'salutationId',
          'street',
        ],
        country: ['name', 'id', 'translated'],
        salutation: ['salutationKey', 'displayName', 'translated', 'id'],
      },
    })
      .then(async (res) => {
        shopwareStore.setAddresses(res.elements);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const deleteAddress = async (addressId: string) => {
    swRequest(`/account/address/${addressId}`, {}, 'DELETE')
      .then((res) => {
        sendNotification({
          type: 'success',
          message: 'Adresse gelöscht',
        });
        loadAddresses();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const modifyAddress = async (addressId: string, body: object) => {
    return swRequest(`/account/address/${addressId}`, body, 'patch')
      .then((res) => {
        sendNotification({
          type: 'success',
          message: 'Adresse geändert',
        });
        refreshSessionContext();
        loadAddresses();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const setActiveShippingAddress = async (
    addressId: string,
    notification: boolean = true
  ) => {
    return swRequest(`/context`, {shippingAddressId: addressId}, 'patch')
      .then(async (res) => {
        if (notification) {
          sendNotification({
            type: 'success',
            message: 'Adresse geändert',
          });
        }
        refreshSessionContext();
        loadAddresses();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const setActiveBillingAddress = async (
    addressId: string,
    notification: boolean = true
  ) => {
    return swRequest(`/context`, {billingAddressId: addressId}, 'patch')
      .then((res) => {
        if (notification) {
          sendNotification({
            type: 'success',
            message: 'Adresse geändert',
          });
        }
        refreshSessionContext();
        loadAddresses();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const setDefaultShippingAddress = async (addressId: string) => {
    return swRequest(
      `/account/address/default-shipping/${addressId}`,
      {},
      'patch'
    )
      .then((res) => {
        sendNotification({
          type: 'success',
          message: 'Adresse geändert',
        });
        refreshSessionContext();
        loadAddresses();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const setDefaultBillingAddress = async (addressId: string) => {
    return swRequest(
      `/account/address/default-billing/${addressId}`,
      {},
      'patch'
    )
      .then((res) => {
        sendNotification({
          type: 'success',
          message: 'Adresse geändert',
        });
        refreshSessionContext();
        loadAddresses();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const addAddress = async (body: object) => {
    return swRequest(`/account/address`, body)
      .then((res) => {
        sendNotification({
          type: 'success',
          message: 'Adresse hinzugefügt',
        });
        refreshSessionContext();
        loadAddresses();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getShippingMethods = async () => {
    return swRequest('/shipping-method', {
      onlyAvailable: true,
    })
      .then((res) => {
        shopwareStore.setShippingMethods(res.elements);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getPaymentMethods = async () => {
    return swRequest('/payment-method?onlyAvailable=true', {
      onlyAvailable: true,
    })
      .then((res) => {
        shopwareStore.setPaymentMethods(res.elements);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const changePaymentMethod = async (
    paymentId: string,
    notification: boolean = true
  ) => {
    return swRequest(`/context`, {paymentMethodId: paymentId}, 'patch')
      .then((res) => {
        if (notification) {
          sendNotification({
            type: 'success',
            message: 'Bezahlmethode geändert',
          });
        }
        refreshSessionContext();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const changeShippingMethod = async (
    shippingId: string,
    notification: boolean = true
  ) => {
    return swRequest(`/context`, {shippingMethodId: shippingId}, 'patch')
      .then((res) => {
        if (notification) {
          sendNotification({
            type: 'success',
            message: 'Versandmethode geändert',
          });
        }
        refreshSessionContext();
        getCart();

        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getOrder = async ({limit = 10, orderId = ''}) => {
    return swRequest(`/order`, {
      associations: {
        addresses: {},
        transactions: {
          sort: '-createdAt',
          associations: {
            paymentMethod: {},
          },
        },
        deliveries: {
          associations: {
            shippingMethod: {},
            positions: {},
          },
        },
        lineItems: {},
      },
      sort: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
      limit: limit,
      filter: [
        ...(orderId
          ? [
              {
                field: 'id',
                type: 'equals',
                value: orderId,
              },
            ]
          : []),
      ],
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    sessionContext,
    refreshSessionContext,
    login,
    logout,
    register,
    sendResetPasswordMail,
    resetPassword,
    loadAddresses,
    deleteAddress,
    modifyAddress,
    setDefaultShippingAddress,
    setDefaultBillingAddress,
    addAddress,
    setActiveShippingAddress,
    setActiveBillingAddress,
    getShippingMethods,
    getPaymentMethods,
    changePaymentMethod,
    changeShippingMethod,
    getOrder,
  };
}
