//writing default setting values in localStorage
export default defineNuxtPlugin((nuxtApp) => {
  const tempStore = useTempStore();
  const store = useDefaultStore();
  const {isLoggedIn, isGuestSession} = useUser();

  // When Hydrating is finished, check if there is a wishlist in localStorage
  const wishListLocalStorage = () => {
    if (
      isLoggedIn.value &&
      !isGuestSession.value &&
      localStorage.getItem('wishlist') &&
      !isHydrating.value
    ) {
      localStorage.removeItem('wishlist');
    } else if (localStorage.getItem('wishlist') && !isHydrating.value) {
      tempStore.setLocalWishlist(JSON.parse(localStorage.getItem('wishlist')));
    }
  };

  const checkDtwparameter = () => {
    // Check for DTW Parameter
    if (store.getDtwwebParameter) {
      window.sessionStorage.setItem('dtwweb', 'true');
    } else if (
      window.sessionStorage.getItem('dtwweb') &&
      JSON.parse(window.sessionStorage.getItem('dtwweb') || 'false')
    ) {
      store.setDtwwebParameter(true);
    }
  };

  //Check if App is Hydrating and set Boolean to Pinia store

  const isHydrating = computed(() => tempStore.isHydrating);

  const checkForHydration = () => {
    if (nuxtApp.isHydrating) {
      setTimeout(() => {
        checkForHydration();
      }, 100);
    } else {
      tempStore.setIsHydrating(false);
    }
  };

  checkForHydration();

  watch(
    () => isHydrating.value,
    () => {
      // DO STUFF WHEN HYDRATING IS FINISHED

      wishListLocalStorage();
      checkDtwparameter();
    }
  );
});
