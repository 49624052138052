// packages/helpers/src/product/getProductFreeShipping.ts
function getProductFreeShipping(product) {
  return product?.shippingFree || false;
}

// packages/helpers/src/product/getProductMainImageUrl.ts
function getProductMainImageUrl(product) {
  return product?.cover?.media?.url || product?.cover?.url || '';
}

// packages/helpers/src/product/getProductMediaGallery.ts
function getProductMediaGallery({product} = {}) {
  return product && product.media
    ? product.media.map((media) => {
        const smallThumb =
          media.media &&
          media.media.thumbnails &&
          media.media.thumbnails.find((thumb) => thumb.width == '400');
        const normalThumb =
          media.media &&
          media.media.thumbnails &&
          media.media.thumbnails.find((thumb) => thumb.width == '800');
        const bigThumb =
          media.media &&
          media.media.thumbnails &&
          media.media.thumbnails.find((thumb) => thumb.width == '1920');
        return {
          icon: {url: smallThumb ? smallThumb.url : media.media.url},
          mobile: {url: normalThumb ? normalThumb.url : media.media.url},
          desktop: {url: bigThumb ? bigThumb.url : media.media.url},
          alt: product.name || ' ',
        };
      })
    : [];
}

// packages/helpers/src/product/getProductName.ts
function getProductName({product} = {}) {
  if (!product) {
    return null;
  }
  return getTranslatedProperty(product, 'name');
}

// packages/helpers/src/product/getProductNumber.ts
function getProductNumber(product) {
  return product?.productNumber;
}

// packages/helpers/src/product/getProductOptions.ts
function getProductOptions({product} = {}) {
  return (
    product?.options?.map((option) => ({
      label: getTranslatedProperty(option, 'name'),
      code: option.id,
      value: getTranslatedProperty(option, 'name'),
      color: option.colorHexCode,
    })) || []
  );
}

// packages/helpers/src/product/getProductProperties.ts
function getProductProperties({product} = {}) {
  const propertyList = product?.properties?.map((property) => ({
    name: getTranslatedProperty(property.group, 'name'),
    value: getTranslatedProperty(property, 'name'),
  }));
  return propertyList || [];
}

// packages/helpers/src/product/getProductReviews.ts
function getProductReviews({product} = {}) {
  if (!product || !product.productReviews) {
    return [];
  }
  return product.productReviews.map(
    ({id, externalUser, customerId, createdAt, content, points}) => ({
      id,
      author: externalUser ? externalUser : customerId,
      date: createdAt,
      message: content,
      rating: points,
    })
  );
}

// packages/helpers/src/product/getProductTierPrices.ts
function getProductTierPrices(product) {
  if (!product || !product.calculatedPrices?.length) {
    return [];
  }
  const size = product.calculatedPrices.length;
  return product.calculatedPrices.map(({unitPrice, quantity}, index) => ({
    label: index === size - 1 ? `from ${quantity}` : `to ${quantity}`,
    quantity,
    unitPrice,
  }));
}

// packages/helpers/src/product/getProductUrl.ts
function getProductUrl(product) {
  if (!product) return '/';
  const seoUrl = product.seoUrls?.[0]?.seoPathInfo;
  return seoUrl ? `/${seoUrl}` : `/detail/${product.id}`;
}

// packages/helpers/src/product/getProductRatingAverage.ts
function getProductRatingAverage(product) {
  return product?.ratingAverage;
}

// packages/helpers/src/product/isProductSimple.ts
function isProductSimple({product} = {}) {
  return !!product?.parentId && product.id !== product.parentId;
}

// packages/helpers/src/product/getProductThumbnailUrl.ts
function getProductThumbnailUrl(product) {
  const coverImageUrlFallback = product?.cover?.media?.url || '';
  const thumbnailImage =
    (product?.cover?.media?.thumbnails?.length &&
      product.cover.media.thumbnails.reduce(function (res, thumb) {
        return thumb.width < res.width ? thumb : res;
      })) ||
    null;
  return thumbnailImage?.url || coverImageUrlFallback;
}

// packages/helpers/src/product/getProductPriceDiscount.ts
function getProductPriceDiscount(product) {
  return product?.calculatedPrice?.listPrice?.discount;
}

// packages/helpers/src/product/getProductPriceDiscountPercentage.ts
function getProductPriceDiscountPercentage(product) {
  return product?.calculatedPrice?.listPrice?.percentage;
}

// packages/helpers/src/product/getProductCalculatedPrice.ts
function getProductCalculatedPrice(product) {
  return product?.calculatedPrice?.unitPrice;
}

// packages/helpers/src/product/getProductCalculatedListingPrice.ts
function getProductCalculatedListingPrice(product) {
  return (
    product?.calculatedPrice?.unitPrice ||
    product?.calculatedPrice?.listPrice?.price
  );
}

// packages/helpers/src/product/getProductRealPrice.ts
function getProductRealPrice(product) {
  if (!product) {
    return;
  }
  const real = product.calculatedPrice;
  if (product.calculatedPrices?.length > 1) {
    return product.calculatedPrices[product.calculatedPrices.length - 1];
  }
  return real;
}

// packages/helpers/src/product/getProductVariantsFromPrice.ts
function getProductVariantsFromPrice(product) {
  if (!product) {
    return;
  }
  const realPrice = getProductRealPrice(product);
  const cheapestPrice = product.calculatedCheapestPrice;
  if (cheapestPrice?.unitPrice != realPrice?.unitPrice) {
    return cheapestPrice.unitPrice;
  }
}

// packages/helpers/src/product/getProductFromPrice.ts
function getProductFromPrice(product) {
  if (!product) {
    return;
  }
  const realPrice = getProductRealPrice(product);
  const displayFromPriceLabel = product.calculatedPrices?.length > 0;
  if (displayFromPriceLabel) return realPrice?.unitPrice;
}

// packages/helpers/src/category/getCategoryUrl.ts
var getCategoryUrl = (category) => {
  if (!category) return '/';
  switch (category.type) {
    case 'link':
      return getTranslatedProperty(category, 'externalLink') || '/';
    case 'folder':
      return '/';
    default:
      return category.seoUrls?.[0]?.seoPathInfo
        ? `/${category.seoUrls?.[0]?.seoPathInfo}`
        : category.id
        ? `/navigation/${category.id}`
        : '/';
  }
};
var isLinkCategory = (category) => category?.type === 'link';

// packages/helpers/src/country/mapCountries.ts
function mapCountries(countries) {
  if (!countries?.length) return [];
  const mappedCountries = countries.map((country) => ({
    name: getTranslatedProperty(country, 'name'),
    id: country.id,
  }));
  return mappedCountries.filter((country) => !!country.name);
}

// packages/helpers/src/cms/getCmsEntityByType.ts
function getCmsEntityByType(page) {
  if (!page?.resourceType) {
    return;
  }
  switch (page.resourceType) {
    case 'frontend.navigation.page':
      return page.category;
    case 'frontend.detail.page':
      return page.product;
  }
}

// packages/helpers/src/cms/getCmsLink.ts
function getCmsLink(content) {
  return content?.data?.url || '';
}

// packages/helpers/src/cms/getCmsLinkTarget.ts
function getCmsLinkTarget(content) {
  const inNewTab = content?.data?.newTab;
  return inNewTab ? '_blank' : '_self';
}

// packages/helpers/src/cms/getCmsTechnicalPath.ts
var PRODUCT_PAGE_PREFIX = '/detail';
var NAVIGATION_PAGE_PREFIX = '/navigation';
var getProductTechnicalPath = (product) =>
  `${PRODUCT_PAGE_PREFIX}/${product.id}`;
var getNavigationTechnicalPath = (cmsPage) =>
  `${NAVIGATION_PAGE_PREFIX}/${cmsPage.resourceIdentifier}`;
function getCmsTechnicalPath(page) {
  if (!page?.resourceType) {
    return;
  }
  switch (page.resourceType) {
    case 'frontend.navigation.page':
      return getNavigationTechnicalPath(page);
    case 'frontend.detail.page':
      return getProductTechnicalPath(page.product);
    default:
      throw Error(
        `Cannot extract a technical URL for provided page type: ${page.resourceType}`
      );
  }
}

// packages/helpers/src/cms/getCmsLayoutConfiguration.ts
function getCmsLayoutConfiguration(content) {
  if (!content) {
    return {
      cssClasses: null,
      layoutStyles: {},
    };
  }
  return {
    cssClasses: content.cssClass,
    layoutStyles: {
      backgroundColor: content.backgroundColor,
      backgroundImage: content.backgroundMedia
        ? `url(${content.backgroundMedia.url})`
        : null,
      marginBottom: content.marginBottom,
      marginLeft: content.marginLeft,
      marginRight: content.marginRight,
      marginTop: content.marginTop,
    },
  };
}

// packages/helpers/src/urlHelpers.ts
function _parseUrlQuery(query) {
  const searchCriteria = {};
  if (!query || typeof query !== 'object') {
    return searchCriteria;
  }
  Object.keys(query).forEach((key) => {
    try {
      searchCriteria[key] =
        typeof query[key] === 'string' &&
        ['{', '['].includes(query[key].charAt(0))
          ? JSON.parse(query[key])
          : query[key];
    } catch (e) {
      console.error(
        '[helpers][parseUrlQuery] Problem with resolving url param: ' + key
      );
    }
  });
  return searchCriteria;
}

// packages/helpers/src/listing/filters.ts
function toggleSearchFilter(currentFilters, filter) {
  let resultFilterValue = void 0;
  if (['range', 'max'].includes(filter.type || '')) {
    if (filter.value) resultFilterValue = filter.value;
  } else {
    let filterCopy = currentFilters[filter.code] || [];
    if (!Array.isArray(filterCopy)) filterCopy = [filterCopy];
    const index = filterCopy.findIndex((element) => element === filter.value);
    if (index < 0) {
      filterCopy.push(filter.value);
    } else {
      filterCopy.splice(index, 1);
    }
    if (filterCopy.length) resultFilterValue = filterCopy;
  }
  return Object.assign(
    {},
    {
      ...currentFilters,
      [filter.code]: resultFilterValue,
    }
  );
}

// packages/helpers/src/listing/getListingFilters.ts
var getFilter = (code, aggregation) => {
  return {
    label: getTranslatedProperty(aggregation, 'name') || code,
    code,
    ...aggregation,
  };
};
function getListingFilters(aggregations) {
  if (!aggregations) {
    return [];
  }
  const transformedFilters = [];
  for (const [aggregationName, aggregation] of Object.entries(aggregations)) {
    if (aggregationName === 'properties' && aggregation.entities) {
      for (const property of aggregation.entities) {
        transformedFilters.push(getFilter(aggregationName, property));
      }
    } else if (!['properties', 'options'].includes(aggregationName)) {
      transformedFilters.push(getFilter(aggregationName, aggregation));
    }
  }
  return transformedFilters;
}

// packages/helpers/src/salutation/mapSalutations.ts
function mapSalutations(salutations) {
  if (!salutations?.length) return [];
  const mappedSalutations = salutations.map((salutation) => ({
    name: salutation.displayName ?? salutation.salutationKey,
    id: salutation.id,
  }));
  return mappedSalutations.filter(
    (mappedSalutation) => mappedSalutation.name !== null
  );
}

// packages/helpers/src/plugins/loadScript.ts
function loadScript(src) {
  return new Promise(function (resolve, reject) {
    if (!document || document.querySelector('script[src="' + src + '"]')) {
      resolve();
      return;
    }
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.async = true;
    el.src = src;
    el.addEventListener('load', resolve);
    el.addEventListener('error', reject);
    el.addEventListener('abort', reject);
    document.head.appendChild(el);
  });
}

// packages/helpers/src/debounce.ts
function debounce(fn, delay = 300) {
  let prevTimer = null;
  return (...args) => {
    if (prevTimer) {
      clearTimeout(prevTimer);
    }
    prevTimer = window.setTimeout(() => {
      fn(...args);
      prevTimer = null;
    }, delay);
  };
}

// packages/helpers/src/getTranslatedProperty.ts
function getTranslatedProperty(element, property) {
  return element?.translated?.[property] || element?.[property] || '';
}

function normalizeChar(str) {
  const charMap = {
    ą: 'a',
    ć: 'c',
    ę: 'e',
    ł: 'l',
    ń: 'n',
    ó: 'o',
    ś: 's',
    ź: 'z',
    ż: 'z',
    à: 'a',
    â: 'a',
    ç: 'c',
    é: 'e',
    è: 'e',
    ê: 'e',
    ë: 'e',
    î: 'i',
    ï: 'i',
    ô: 'o',
    ù: 'u',
    û: 'u',
    ÿ: 'y',
    å: 'a',
    æ: 'ae',
    ø: 'o',
    ñ: 'n',
    ğ: 'g',
    İ: 'I',
    ş: 's',
    ç: 'c',
  };
  if (str) {
    return str
      .split('')
      .map((char) => charMap[char] || char)
      .join('');
  } else {
    return null;
  }
}

function calculateTotalDiscount(discounts) {
  if (discounts) {
    return discounts.reduce((acc, discount) => {
      return acc + (discount.totalPrice || discount.price.totalPrice);
    }, 0);
  } else {
    return 0;
  }
}

const getMatchingCartProduct = (product, cart) => {
  if (product && cart) {
    const productInCart = cart?.lineItems?.find((item) => {
      return item.id === product.id;
    });
    return productInCart;
  }
};

const prettyPath = (fn) => {
  const umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  };

  function replaceUmlaute(str) {
    return str
      .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
        const big = umlautMap[a.slice(0, 1)];
        return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
      })
      .replace(
        new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'),
        (a) => umlautMap[a]
      );
  }

  let str = fn
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace('---', '-')
    .replace(/[^a-zA-Z0-9 ]/g, '');

  return replaceUmlaute(str);
};

const getDate = (date) => {
  const dateObject = new Date(date);
  const dateDay = dateObject.getDate();
  const dateMonth = dateObject.getMonth() + 1;
  const dateYear = dateObject.getFullYear();

  const completeDate = dateDay + '.' + dateMonth + '.' + dateYear;

  return completeDate;
};

export {
  _parseUrlQuery,
  debounce,
  getCategoryUrl,
  getCmsEntityByType,
  getCmsLayoutConfiguration,
  getCmsLink,
  getCmsLinkTarget,
  getCmsTechnicalPath,
  getListingFilters,
  getProductCalculatedListingPrice,
  getProductCalculatedPrice,
  getProductFreeShipping,
  getProductFromPrice,
  getProductMainImageUrl,
  getProductMediaGallery,
  getProductName,
  getProductNumber,
  getProductOptions,
  getProductPriceDiscount,
  getProductPriceDiscountPercentage,
  getProductProperties,
  getProductRatingAverage,
  getProductRealPrice,
  getProductReviews,
  getProductThumbnailUrl,
  getProductTierPrices,
  getProductUrl,
  getProductVariantsFromPrice,
  getTranslatedProperty,
  isLinkCategory,
  isProductSimple,
  loadScript,
  mapCountries,
  mapSalutations,
  toggleSearchFilter,
  normalizeChar,
  calculateTotalDiscount,
  getMatchingCartProduct,
  prettyPath,
  getDate,
};
